import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LoginDataType } from "../../_App/Types/Forms/LoginDataType";
import { useAppDispatch } from "../../_App/Redux/hooks";
import { login } from "../../_App/Redux/Slices/auth/authSlice";
import { toast } from "react-toastify";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<LoginDataType>();
  const [showPwD, SetShowPwd] = useState(false);

  const onSubmit: SubmitHandler<LoginDataType> = async (data) => {
    console.log(data);
    let res = await dispatch(login(data));
    console.log("res", res);
    if (res && res.payload && res?.payload?.token) {
      window.location.href = "/";
    } else if (res.payload.success === false) {
      toast.error("Error message!");
    }
  };

  return (
    <div className="login-page">

      <div className="form-container">
        <div className="form-form">
          <div className="form-form-wrap">
            <div className="form-container p-0">
              <div
                className="form-content"
                style={{
                  background: "#fff",
                  padding: "50px 40px",
                  borderRadius: "10px",
                  boxShadow: "0 0 4px 2px rgba(31, 45, 61, 0.1)",
                }}
              >
                <h1 className="mb-5">
                  Se connecter a{" "}
                  <a href="index.html">
                    <span className="brand-name">ECOPACK</span>
                  </a>
                </h1>
                <form className="text-left" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form">
                    <div
                      id="username-field"
                      className="field-wrapper input mb-3"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-user"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                        <circle cx="12" cy="7" r="4"></circle>
                      </svg>
                      <input
                        id="username"
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        {...register("email", {
                          required: "email is required",
                        })}
                      />
                    </div>

                    <div
                      id="password-field"
                      className="field-wrapper input mb-5"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-lock"
                      >
                        <rect
                          x="3"
                          y="11"
                          width="18"
                          height="11"
                          rx="2"
                          ry="2"
                        ></rect>
                        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                      </svg>
                      <input
                        id="password"
                        type={showPwD ? "text" : "password"}
                        className="form-control"
                        placeholder="Mot de passe"
                        {...register("password", {
                          required: "password is required",
                        })}
                      />
                    </div>
                    <div className="d-sm-flex justify-content-between">
                      <div className="field-wrapper toggle-pass">
                        <p className="d-inline-block">
                          Afficher le mot de passe
                        </p>
                        <label className="switch s-primary">
                          <input
                            type="checkbox"
                            id="toggle-password"
                            className="d-none"
                            onChange={(e) => SetShowPwd(!showPwD)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="field-wrapper">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          value=""
                        >
                          Connexion
                        </button>
                      </div>
                    </div>

                    {/* <div className="field-wrapper text-center keep-logged-in">
                      <div className="n-chk new-checkbox checkbox-outline-primary">
                        <label className="new-control new-checkbox checkbox-outline-primary">
                          <input
                            type="checkbox"
                            className="new-control-input"
                          />
                          <span className="new-control-indicator"></span>Reste
                          connecté
                        </label>
                      </div>
                    </div> */}

                    {/* <div className="field-wrapper">
                      <a
                        href="auth_pass_recovery.html"
                        className="forgot-pass-link"
                      >
                        Mot de passe oublié ?
                      </a>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <p className="terms-conditions">
          © 2024 Tous droits réservés.{" "}
          <a
            href="https://nomadis.online/"
            style={{ color: "rgb(201 226 249)" }}
          >
            Clediss
          </a>{" "}
        </p>
      </div>
    </div>
  );
};
export default LoginPage;
